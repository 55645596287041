import qs from "qs";
import cloneDeep from "./cloneDeep";
import {isObject} from "./is";
/**
 * 
 * @param {function} fn 请求方法
 * @param {string} fnName 方法名
 * @param {number} time 缓存时间(以分钟为单位 默认5分钟)
 */
export default function withMemeryCache(fn,fnName,time=5){
	let __cache__ = {};
	let last = 0;
	fnName = fnName || fn.name;
	if(typeof time!=="number") time = 5; //默认5分钟
	time = time * 60 * 1000; //转成毫秒数
    const outputFn = function(...args) {
        const KEY = `AJAX_API_MEMERY_CACHE_${fnName}_`;
        const key = KEY + args.map((arg) => {
            if (Array.isArray(arg) || isObject(arg)) {
                return qs.stringify(arg);
            } else {
                return arg;
            }
        }).join("&");
		const cache = __cache__[key];
		const now = new Date().getTime();
        if(cache){
			const dis = now - last;
			if(dis<=time){
				last = now;
				return Promise.resolve(cloneDeep(cache));
			}
			__cache__[key] = null;
			delete __cache__[key];
		}
		last = now;
        return fn.apply(null, args).then((res) => {
            if (res.code == 200) {
				__cache__[key] = res;
            }
            return res;
        });
	}
	outputFn.clearCache = () => {
		__cache__ = {};
		last = 0;
	}
	return outputFn;
}
