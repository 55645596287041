import * as money from "../helper/money";
import {unixTimeToDateTime,unixTimeToDate,getDateFlag} from "../helper/datetime";
import formateDate from "@/helper/formateDate.js";
//把后端返回的以元为单位的价格转成分
export function $tofen(val){
	return money.tofen(val)
}
//把后端返回的以分为单位的价格转成元
export function $toyun(val){
	return money.toyun(val)
}
/**
 * 把后端返回的以分为单位的价格转成元，并且自动加上小数点后两位
 * @param {String|Number} fen
 * @return {String} "1.00"
 */
export function $formatFenToYuan(fen){
	return money.formatFenToYuan(fen);
}

//unix时间戳转日期时间
export function $unixTimeToDateTime(timeStamp){
	return unixTimeToDateTime(timeStamp);
}

//unix时间戳转日期
export function $unixTimeToDate(timeStamp){
	return unixTimeToDate(timeStamp);
}

export function $mapDateFlag(date){
	return getDateFlag(date);
}

export function $formateDate(value) {
    return formateDate(value);
}



