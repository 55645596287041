import dayjs from "dayjs";
const DATE_FORMAT = "YYYY-MM-DD";
const DATE_BEGIN_FORMAT = "YYYY-MM-DD 00:00:00";
const DATE_END_FORMAT = "YYYY-MM-DD 23:59:59";
//unix时间戳转日期时间
export function unixTimeToDateTime(timeStamp){
	if(!timeStamp) return "";
	var myDate = new Date(timeStamp * 1000);
	var year = myDate.getFullYear();
	var month = myDate.getMonth() + 1;
	var day = myDate.getDate();
	var hour = myDate.getHours().toString();
	if(month<10) month = "0" + month;
	if(day<10) day = "0" + day;
	if (hour.length == 1) hour = '0' + hour;
	var minute = myDate.getMinutes().toString();
	if (minute.length == 1) minute = '0' + minute;
	var second = myDate.getSeconds().toString();
	if (second.length == 1) second = '0' + second;
	return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}

//unix时间戳转日期
export function unixTimeToDate(timeStamp){
	const datetime = unixTimeToDateTime(timeStamp);
	return datetime.substring(0,10);
}

export function getWeekDay(date){
	if(!date) return "";
	var week = {
		0 : "日",
		1 : "一",
		2 : "二",
		3 : "三",
		4 : "四",
		5 : "五",
		6 : "六"
	};
	var day = new Date(date).getDay();
	return week[day] ? week[day] : "";
}
export function isBefore(dateA,dateB){
	return dayjs(dateA).isBefore(dateB);
}
export function getDayBegin(date){
	return dayjs(date).format(DATE_BEGIN_FORMAT);
}
export function getDayEnd(date){
	return dayjs(date).format(DATE_END_FORMAT);
}
export function getTodayBegin(){
	return dayjs().format(DATE_BEGIN_FORMAT);
}
export function getTodayEnd(){
	return dayjs().format(DATE_END_FORMAT);
}
export function getToday(){
	return dayjs().format(DATE_FORMAT)
}
export function getNextDay(date){
	return dayjs(date).add(1,"day").format(DATE_FORMAT);
}
export function getPrevDay(date){
	return dayjs(date).subtract(1,"day").format(DATE_FORMAT);
}

export function isToday(date){
	return date==getToday();
}
export function isTomorrow(date){
	return date==getNextDay(getToday());
}

export function getDateFlag(date){
	if(!date) return "";
	const today = getToday()
	if(date==today) return "今天";
	if(date==getNextDay(today)) return "明天";
	return "周" + getWeekDay(date);
}

export const CalendarCore = {
	outputDate : function(yearmonth){
		var that = this;
		if(!yearmonth) yearmonth = this.gettoday();
		yearmonth = yearmonth.length==10 ? yearmonth.substring(0,7) : yearmonth;
		var nowtime=new Date();
		nowtime.setHours(0,0,0,0);
		nowtime=nowtime.getTime();//凌晨时间
		var begintime=yearmonth+"-01";
		begintime=begintime.split("-");
		begintime=begintime.join("/");
		var beginDate=new Date(begintime);
		var month=beginDate.getMonth()+1;
		var days=(new Date(beginDate.getFullYear(),beginDate.getMonth()+1,0)).getDate();
		var monthdays=days;
		var emptydays=beginDate.getDay();
		var endtime=yearmonth+"-"+days;
		endtime=endtime.split("-");
		endtime=endtime.join("/");
		var endDate=new Date(endtime);
		days+=beginDate.getDay()+(7-endDate.getDay());
		beginDate.setTime(beginDate.getTime()-(24*3600000*beginDate.getDay()));
		var resultArr = [];
		var dateArr = [];
		for(var i=0;i<days-1;i++){
			var json = {};
			var date=beginDate.getFullYear()+"-"+this.strpad((beginDate.getMonth()+1))+"-"+this.strpad(beginDate.getDate());
			var beginDate_day = that.strpad(beginDate.getDate());
			json["weeken"] = (i%7);
			if(i<emptydays){
				json["day"] = "";
				json["month"] = month-1;
				json["prevMonth"] = true;
			}else if(i>=monthdays+emptydays){
				json["day"] = "";
				json["month"] = month+1;
				json["nextMonth"] = true;
			}else if(beginDate.getTime()<nowtime){
				json["day"] = beginDate_day;
				json["date"] = date;
				json["today"] = "before";
				json["month"] = "current";
				json["yearmonth"] = yearmonth;
			}else if(beginDate.getTime()==nowtime){
				json["day"] = beginDate_day;
				json["date"] = date;
				json["today"] = "today";
				json["month"] = "current";
				json["yearmonth"] = yearmonth;
			}else{
				json["day"] = beginDate_day;
				json["date"] = date;
				json["today"] = "after";
				json["month"] = "current";
				json["yearmonth"] = yearmonth;
			}
			dateArr.push(json);
			if(dateArr.length==7){
				resultArr.push(dateArr);
				dateArr=[];
			}
			beginDate.setTime(beginDate.getTime()+24*3600000);
		}
		return resultArr;
	},
	strpad:function(str){
		str=String(str);
		if(str.length==1){
			return "0"+str;
		}else{
			return str;
		}
	},
	getnowYearMonth : function(){
		var yearmonth = getToday().split("-");
		return yearmonth[0]+"-"+yearmonth[1];
	},
	nextday : function(date){
		if(!date) date = getToday()
		return getNextDay(date);
	},
	//获取前一天
	prevDay : function(date){
		if(!date) date = getToday()
		return getPrevDay(date);
	},
	/**
	 * 获取指定日期的前几天
	 * 从beginDate往前推几天(days)
	 * 如果beginDate缺省，则默认从今天算起
	 * containBeginDate : 是否包含beginDate
	 */
	prevDays : function(beginDate,dayCount,containBeginDate){
		var that = this;
		var result = [];
		var args = arguments;
		var len = args.length;
		var _beginDate = len>1 ? args[0] : getToday();
		var _dayCount = len>1 ? args[1] : args[0];
		if(!_beginDate || !_dayCount) return result;
		if(len<3){
			containBeginDate = false;
		}else{
			containBeginDate = !!containBeginDate;
		}

		var _getPrev = function(date){
			var prev = that.prevDay(date);
			result.push(prev);
			_dayCount--;
			if(_dayCount>0) _getPrev(prev);
		};

		_getPrev(_beginDate);

		if(containBeginDate){
			result.unshift(beginDate);
			return result;
		}

		return result;

	},
	//获取后一天
	nextDay : function(date){
		return getNextDay(date);
	},
	/**
	 * 获取指定日期的后几天
	 * 从beginDate往后推几天(days)
	 * 如果beginDate缺省，则默认从今天算起
	 * containBeginDate : 是否包含beginDate
	 */
	nextDays : function(beginDate,dayCount,containBeginDate){
		var that = this;
		var result = [];
		var args = arguments;
		var len = args.length;
		var _beginDate = len>1 ? args[0] : getToday();
		var _dayCount = len>1 ? args[1] : args[0];
		if(!_beginDate || !_dayCount) return result;
		if(len<3){
			containBeginDate = false;
		}else{
			containBeginDate = !!containBeginDate;
		}

		var _getNext = function(date){
			var prev = that.nextDay(date);
			result.push(prev);
			_dayCount--;
			if(_dayCount>0) _getNext(prev);
		};

		_getNext(_beginDate);

		if(containBeginDate){
			result.unshift(beginDate);
			return result;
		}

		return result;

	},
	nextMonth : function(yearmonth,ifContainDay){
		if(!ifContainDay) return this._siblingMonth(yearmonth,"next");
		var date = yearmonth.length==10 ? yearmonth : yearmonth + "-01";
		var arr = date.split('-');
		var year = arr[0]; //获取当前日期的年份
		var month = arr[1]; //获取当前日期的月份
		var day = arr[2]; //获取当前日期的日
		var days = new Date(year, month, 0);
		days = days.getDate(); //获取当前日期中的月的天数
		var year2 = year;
		var month2 = parseInt(month) + 1;
		if (month2 == 13) {
			year2 = parseInt(year2) + 1;
			month2 = 1;
		}
		var day2 = day;
		var days2 = new Date(year2, month2, 0);
		days2 = days2.getDate();
		if (day2 > days2) {
			day2 = days2;
		}
		if (month2 < 10) {
			month2 = '0' + month2;
		}
		var t2 = year2 + '-' + month2 + '-' + day2;
		return t2;
	},
	prevMonth : function(yearmonth,ifContainDay){
		if(!ifContainDay) return this._siblingMonth(yearmonth,"prev");
		var date = yearmonth.length==10 ? yearmonth : yearmonth + "-01";
		var arr = date.split('-');
		var year = arr[0]; //获取当前日期的年份
		var month = arr[1]; //获取当前日期的月份
		var day = arr[2]; //获取当前日期的日
		var days = new Date(year, month, 0);
		days = days.getDate(); //获取当前日期中月的天数
		var year2 = year;
		var month2 = parseInt(month) - 1;
		if (month2 == 0) {
			year2 = parseInt(year2) - 1;
			month2 = 12;
		}
		var day2 = day;
		var days2 = new Date(year2, month2, 0);
		days2 = days2.getDate();
		if (day2 > days2) {
			day2 = days2;
		}
		if (month2 < 10) {
			month2 = '0' + month2;
		}
		var t2 = year2 + '-' + month2 + '-' + day2;
		return t2;
	},
	nextYear : function(year){

		year = year ? (year+"") : getToday();

		return year.replace(/(\d{4})(.*)/,function($1,$2,$3){
			return ($2*1+1) + $3;
		})

	},
	prevYear : function(year){
		year = year ? (year+"") : getToday();

		return year.replace(/(\d{4})(.*)/,function($1,$2,$3){
			return ($2*1-1) + $3;
		})
	},
	getNowDateTime : function(date){
		date = date || new Date();
		var y = date.getFullYear();
		var m = date.getMonth()+1;
		var d = date.getDate();
		var hour = date.getHours();
		var minu = date.getMinutes();
		var second = date.getSeconds();
		m = this.strpad(m);
		d = this.strpad(d);
		hour = this.strpad(hour);
		minu = this.strpad(minu);
		second = this.strpad(second);
		return y+"-"+m+"-"+d + " "+hour+":"+minu+":"+second;
	},

	_siblingMonth : function(yearmonth,nextOrPrev){ //2015-06
		yearmonth = yearmonth || "";
		if(yearmonth.length==7){
			yearmonth = yearmonth;
		}else if(yearmonth.length==10){
			yearmonth = yearmonth.substring(0,7);
		}else{
			yearmonth = getToday().substring(0,7);
		}
		if(!nextOrPrev) nextOrPrev = "next";
		var time=yearmonth+"-01";
		time=time.split("-");
		time=time.join("/");
		time=new Date(time);
		var lasttime=new Date(time.getFullYear(),time.getMonth()-1,1);
		var lastmonth=lasttime.getFullYear()+"-"+this.strpad(lasttime.getMonth()+1);
		var nexttime=new Date(time.getFullYear(),time.getMonth()+1,1);
		var nextmonth=nexttime.getFullYear()+"-"+this.strpad(nexttime.getMonth()+1);
		return nextOrPrev=="next" ? nextmonth : lastmonth;
	},
	/**
	 * @description 给出时间戳，返回日期
	 */
	getDate__useTimestamp: function (timestamp) {
		var date = new Date(timestamp);
		var y = date.getFullYear();
		var m = date.getMonth()+1;
		m = this.strpad(m);
		var d = date.getDate();
		d = this.strpad(d);
		return y+"-"+m+"-"+d;
	},

	/**
	 * 计算两个日期间相差的天数
	 */
	getDateDiff: function(startDate, endDate) {
		var startTime = new Date(Date.parse(startDate.replace(/-/g, "/"))).getTime();
		var endTime = new Date(Date.parse(endDate.replace(/-/g, "/"))).getTime();
		var dates = Math.abs((startTime - endTime)) / (1000 * 60 * 60 * 24);
		return dates;
	},

	getCurYearmonth : function(){
		return $("#calendarHead").attr("data-date");
	}
}


export function getBetweenDates(begin,end){
	let result = [];
	let date = "";
	if(!begin || !end || typeof begin!=="string" || typeof end!=="string") return result;
	if(begin===end) return result;
	if(!dayjs(begin).isBefore(dayjs(end))) return result;
	while(date!==end){
		date = dayjs(begin).add(1,"day").format("YYYY-MM-DD");
		begin = date;
		result.push(date);
	}
	result.pop()
	return result;
}
