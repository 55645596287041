<template>
<div class="app-sidebar">
	<!-- <div class="dl home">
		<div @click="onHomeRouteClick" class="dt" :class="{active:$route.name=='Home'}" flex="cross:center box:justify" style="margin-bottom:5px">
			<svg-icon class="leftIcon" name="icon-pft_icon_leftmenu_home"></svg-icon>
			<span class="label">首页</span>
		</div>
	</div> -->
	<drawer v-for="route in sidebarRoutes" :key="route.path" class="dl" :init-open="true">
		<template v-slot:header="slotProps">
			<div class="dt" flex="cross:center box:justify">
				<svg-icon class="leftIcon" name="icon-pft_icon_leftmenu_company"></svg-icon>
				<span class="label">{{route.meta.title}}</span>
				<svg-icon :class="{active:slotProps.open}" class="triBtn" name="icon-pft_icon_arrow_down"></svg-icon>
			</div>
		</template>
		<template v-if="route.children" v-slot:content>
			<div v-for="subRoute in route.children" :key="route.path+subRoute.path" :class="{active:$route.name==subRoute.name}" class="dd">
				<a @click="onSubItemClick(route,subRoute)">{{subRoute.meta.title}}</a>
			</div>
		</template>
	</drawer>
</div>
</template>
<script>
import Drawer from "@/component/drawer";
import {getSidebarRoute} from "@/router/routes";
export default {
	name : "AppSidebar",
	data(){
		return{
			sidebarRoutes : getSidebarRoute()
		}
	},
	components : {
		Drawer
    },
    mounted(){
        console.log('sidebarRoutes',this.sidebarRoutes)
    },
	methods : {
		onSubItemClick(route,subRoute){
			const {name} = subRoute;
			if(!name) return;
			if(this.$route.name==name) return;
			this.$router.push({name});
		},
		onHomeRouteClick(){
			if(this.$route.name=="Home") return;
			this.$router.push({name:"Home"});
        },
        routeClick(route){
            if(!route.router)return
            if(this.$route.name==route.router) return;
            this.$router.push({name:route.router});
        }
	}
}
</script>
<style lang="scss" scoped>
.app-sidebar{
	// position: fixed;
	// top: $app-header-height;
	// left: 0;
	// bottom: 0;
	width: $app-sidebar-width;
	background: #fff;
	padding-top: 16px;
    z-index: 1;
    overflow-y:auto;
	.dl{
		margin-bottom: 0;
		.dt{
			font-size: 14px;
			font-weight: bold;
			color: $strong;
			padding: 0 22px;
			height: 40px;
			border-right: 2px solid #fff;
			cursor: pointer;
			background: #fff;
			user-select: none;
			.leftIcon{
				position: relative;
				top: -2px;
				font-size: 18px;
				fill: #818899;
				margin-right: 10px;
			}
			&.active{
				color: $brand;
				background: #EEF3FF;
				border-right-color: $brand;
			}
			.triBtn{
				transform: rotate(-180deg);
				transition: transform 0.3s;
				&.active{
					transform: rotate(0deg);
				}
			}
		}
		.dd{
			padding-left: 50px;
			border-right: 2px solid #fff;
			cursor: pointer;
			margin-bottom: 10px;
			a{
				display: block;
				height: 40px;
				line-height: 40px;
				user-select: none;
			}
			&.active{
				color: $brand;
				background: #EEF3FF;
				border-right-color: $brand;
			}
		}
		&.home{
			.dt{
				cursor: pointer;
			}
		}
		
	}
	
}
</style>