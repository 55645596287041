import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		//被访问过的路由名(由此来定义keep-alive的include，即只要某个路由被访问过，下回再访问时就直接走keep-alive缓存，而不是频繁的销毁重建)
		visitedRouteName : [],
	},
	mutations: {
		pushVisitedRouteName(state,{name:routeName="",meta:routeMeta={}}){
			if(typeof routeName!=="string" || routeName=="") return;
			if(routeMeta && routeMeta.keepAlive===false) return;
			if(state.visitedRouteName.includes(routeName)) return;
			state.visitedRouteName.push(routeName)
		},
		deleteVisitedRouteName(state,routeName=""){
			if(typeof routeName!=="string" || routeName=="") return;
			const arr = state.visitedRouteName.slice();
			for(let i=0; i<arr.length; i++){
				if(arr[i]==routeName){
					arr.split(i,1)
				}
			}
			state.visitedRouteName = arr;
		},
		clearVisitedRouteName(state){
			state.visitedRouteName = [];
		}
	},
	actions: {},
	modules: {
		app
	},
});
