<template>
    <div class="app-layout" flex="dir:top box:first">
        <app-header/>
        <div class="container" flex="dir:left box:first" id="container">
            <!-- <app-sidebar/> -->
            <app-sidemenu/>
            <app-main/>
        </div>
    </div>
</template>

<script>
    import AppHeader from "./header.vue";
    import AppSidebar from "./sidebar.vue";
    import AppSidemenu from "./sidemenu.vue";
    import AppMain from "./main.vue";
    export default {
        name: "Layout",
        components: {
            AppHeader,
            AppSidebar,
            AppSidemenu,
            AppMain,
        },
      
    }
</script>

<style lang="scss" scoped>
    .app-layout {
        height: 100%;
        width: 100;
        overflow: auto;
        .container {
            overflow-y: auto;
        }
    }
</style>
