import Layout from "@/layout";

//不需要登录的页面
const constantRoutes = [{
	path : "/login",
	name : "Login",
	component : () => import(/* webpackChunkName: "Login" */ "@/page/login"),
}]
//404页面
const notFound = {
	path: "*",
	name: "NotFound",
	component : () => import(/* webpackChunkName: "notFound" */ "@/page/404"),
}
const initRoute = {
    path:'/',
    redirect:'/order'
}
const orderRoute = {
	path: "/order",
	component: Layout,
    redirect : "/order/payOrder",
    meta : {
		title : "订单管理",
        icon : "leftmenu_company",
		elIcon: "tickets",
        visibleInSidebar : true,
    },
	children : [{
		path : "payOrder",
		name : "payOrder",
		component : () => import(/* webpackChunkName: "order" */ "@/page/order/payOrder"),
		meta : {
            title : "支付订单查询",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "refundOrder",
		name : "refundOrder",
		component : () => import(/* webpackChunkName: "order" */ "@/page/order/refundOrder"),
		meta : {
            title : "退款订单查询",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "orderDetail",
		name : "orderDetail",
		component : () => import(/* webpackChunkName: "order" */ "@/page/order/orderDetail"),
		meta : {
            title : "订单详情",
            roles : ['admin'],
            visibleInSidebar : false,
			keepAlive: false,
		}
	}]
}

const configRoute = {
	path: "/config",
	component: Layout,
    redirect : "/config/channelConfig",
    meta : {
		title : "配置管理",
        icon : "leftmenu_company",
		elIcon: "setting",
        visibleInSidebar : true,
    },
	children : [{
		path : "channelConfig",
		name : "channelConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/channelConfig"),
		meta : {
            title : "渠道配置",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "payChannelConfig",
		name : "payChannelConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/payChannelConfig"),
		meta : {
			title : "支付渠道配置",
			roles : ['admin'],
			visibleInSidebar : true,
		}
	},{
		path : "clientConfig",
		name : "clientConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/clientConfig"),
		meta : {
            title : "业务方配置",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "supplierConfig",
		name : "supplierConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/supplierConfig"),
		meta : {
            title : "供应商配置",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "employeeConfig",
		name : "employeeConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/employeeConfig"),
		meta : {
            title : "商户配置",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "userLimitConfig",
		name : "userLimitConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/userLimitConfig"),
		meta : {
            title : "用户权限配置",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "secondaryPwdConfig",
		name : "secondaryPwdConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/secondaryPwdConfig"),
		meta : {
            title : "二级密码配置",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	},{
		path : "limitConfig",
		name : "limitConfig",
		component : () => import(/* webpackChunkName: "config" */ "@/page/config/limitConfig"),
		meta : {
            title : "权限配置",
            roles : ['admin'],
            visibleInSidebar : false,
		}
	}]
}

const logRoute = {
	path: "/log",
	component: Layout,
    redirect : "/log/logList",
    meta : {
		title : "日志",
        icon : "leftmenu_company",
		elIcon: "message",
        visibleInSidebar : true,
    },
	children : [{
		path : "logList",
		name : "logList",
		component : () => import(/* webpackChunkName: "order" */ "@/page/log/logList"),
		meta : {
            title : "日志列表",
            roles : ['admin'],
            visibleInSidebar : true,
		}
	}]
}


/**
 * 判断当前用户能否进入到某个路由
 * @param {object} route 路由配置对象
 * @param {array} roles  当前用户所拥有的角色列表
 */
export function checkHasPermission(route,roles){
	const {meta} = route;
	const routeRoles = meta && meta.roles;
	if(!routeRoles) return true; //如果连meta或roles都没定义，那就是不需要鉴权的路由，直接进入
	return routeRoles.some((role) => roles.includes(role));
}

const routes = [
    ...constantRoutes,
	// permissionRoutes,
    notFound,
    initRoute,
    orderRoute,
    configRoute,
    logRoute
    // settingRoutes,
    // personRoutes
];
/**
 * 根据路由生成左侧菜单栏sidebar 后期需要加入权限判断
 */
export function getPermissionRoute(routesArr=routes,roles=['admin']){
    
	const newRoutes = [];
	routesArr.forEach((route) => {
		const cp = {...route};
		delete cp.component; //同步路由时，component是个plain object，在这里并不需要
		const {children} = cp;
		if(!children){
			const ok = checkHasPermission(route,roles);
			if(ok) newRoutes.push(cp);
		}else{//递归子孙路由，因为后期路由可能不止2层
			const child = getPermissionRoute(cp.children,roles);
			if(child.length>0){
				cp.children = child;
				newRoutes.push(cp);
			}
		}
	})
	return newRoutes;
}
export function getSidebarRoute(roles=['admin'],routesArr=routes){
    console.log(routesArr,'routesArr')
	const permissionRoutes = getPermissionRoute(routesArr,roles);
	const newRoutes = [];
	permissionRoutes.forEach((route) => {
		const {meta,children} = route;
		if(!children){
			const visibleInSidebar = meta && meta.visibleInSidebar;
			if(visibleInSidebar===true){
				newRoutes.push(route);
			}
		}else{
			const child = getSidebarRoute(roles=['admin'],route.children);
			if(child.length>0){
				route.children = child;
				newRoutes.push(route);
			}
		}
	})
	return newRoutes;
}

export default routes