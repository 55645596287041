/**
 * 验证手机号(目前只要是纯数字且以1开头且数位是11位就判定是合法的手机号)
 * @param {string} mobile 
 */
export function mobile(mobile){
	if(typeof mobile!=="string") return ["格式错误",-1]
	if(isNaN(mobile)) return ["格式错误",0]
	if(mobile.charAt(0)!="1") return ["格式错误",1];
	if(mobile.length!=11) return ["格式错误",2];
	return ["",200];
}

/**
 * 身份证号合法性验证
 * 支持15位和18位身份证号
 * 支持地址编码、出生日期、校验位验证
 */
export function idcard(code){
	if(typeof code!=="string" || !code) return ["身份证号码为空",-100];
	const city={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "};

	if(!code || code.length !== 15 && code.length !== 18) return ["身份证号格式错误",-1];

	if(!city[code.substr(0,2)]){
		return ["地址编码错误",2]
	}

	if(code.length === 15 && !/^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/.test(code)) {
		return ["身份证号格式错误",1]
	}

	if(code.length === 18) {
		if(!/^\d{6}(18|19|20)\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) return ["身份证号格式错误",1]

		//18位身份证需要验证最后一位校验位
		code = code.split('');
		//∑(ai×Wi)(mod 11)
		//加权因子
		var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
		//校验位
		var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
		var sum = 0;
		var ai = 0;
		var wi = 0;
		for (var i = 0; i < 17; i++)
		{
			ai = code[i];
			wi = factor[i];
			sum += ai * wi;
		}
		var last = parity[sum % 11];
		if(parity[sum % 11] != code[17]){
			return ["校验位错误",3]
		}
		
	}

	return ["",200];
}