<template>
<a-breadcrumb class="breadCrumbBar" :routes="routes">
    <template slot="itemRender" slot-scope="{route, params, routes, paths}">
      <span v-if="[routes.length-1,0].includes(routes.indexOf(route))">
        {{route.meta.title}}
      </span>
      <router-link v-else :to="paths.join('/')">
        {{route.meta.title}}
      </router-link>
    </template>
</a-breadcrumb>
</template>
<script>
export default {
	name : "BreadCrumb",
	computed : {
		routes(){
			return this.$route.matched || [];
		},
	}
}
</script>
<style lang="scss">
.breadCrumbBar{
	> span{
		&:last-child{
			font-size: 14px;
			color: $strong;
			font-weight: bold;
		}
	}
}
</style>