import NP from "number-precision";
export function strip(val){
	return NP.strip(val*1);
}
//把后端返回的以元为单位的价格转成分
export function tofen(val){
	return NP.strip(val*100);
}
//把后端返回的以分为单位的价格转成元
export function toyun(val){
	return NP.strip(val/100);
}
/**
 * 把后端返回的以分为单位的价格转成元，并且自动加上小数点后两位
 * @param {String|Number} fen
 * @return {String} "1.00"
 */
export function formatFenToYuan(fen){
	if(typeof fen==="undefined") return "";
	const yuan = NP.strip(fen/100);
	const yuanArr = String(yuan).split(".");
	if(yuanArr.length==1) return `${yuanArr[0]}.00`;
	if(yuanArr.length==2){
		let [first,second] = yuanArr;
		if(second.length<=1){
			second = `${second}0`;
		}
		return `${first}.${second}`;
	}
	return String(yuan);
}