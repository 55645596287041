<template>
<div id="app">
	<router-view />
</div>
</template>
<script>
import { Message } from "element-ui";
export default {
	name : "App",
	data(){
		return{
		}
    },
    mounted(){
        console.log('appapp')
    },
}
</script>
<style>
html{height: 100%;width:100%;}
body{height: 100%;width:100%;}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height:100%;
    width:100%;
}
#nprogress .bar{
	background: #fff!important;
	height: 1px!important;
}
*{
    margin:0;
    padding:0;
}
.el-dialog__header {
    padding: 0!important;
}
    
.el-dialog__body {
    padding: 0!important;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    background-color: #0003;
    border-radius: 10px;
    transition: all .2s ease-in-out;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
}
.c-success {
  color: #67C23A;
}
.c-danger {
  color: #F56C6C;
}
.ant-message{
  z-index: 2023;
}
</style>
