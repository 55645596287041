<template>
<div class="comp drawer" :style="{paddingTop:paddingTop}">
	<div @click="onSwitch" ref="header" class="drawer-header">
		<slot name="header" v-bind:open="open">
			<div ref="topTit" class="topTit" :class="{open:open}" flex="cross:center box:last">
				<span class="t">{{title}}</span>
				<van-icon name="arrow-down"></van-icon>
			</div>
		</slot>
	</div>
	<transition name="drawer-slide">
		<div v-show="open" class="drawer-content">
			<slot name="content"></slot>	
		</div>	
	</transition>
</div>	
</template>
<script>
export default{
	props : {
		title : String,
		initOpen : {
			type : Boolean
		}
	},
	data(){
		return{
			open : this.initOpen,
			paddingTop : "1.2rem"
		}
	},
	created(){
		if(typeof this.initOpen==="boolean"){
			this.open = this.initOpen;
		}
	},
	mounted(){
		const header = this.$refs.header;
		if(header){
			this.paddingTop = header.offsetHeight + "px";
		}
	},
	methods : {
		onSwitch(e){
			this.open = !this.open;
			this.$emit("switch",this.open);
		}
	}
}
</script>

<style lang="scss">
.comp.drawer{
	overflow: hidden;
	position: relative;
	.drawer-header{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
	.topTit{
		height: 1.2rem;
		padding: 0 0.35rem;
		background: #fff;
		border-bottom: 1px solid $bc;
		.van-icon-arrow-down{
			transition: transform 0.3s;
		}
		&.open .van-icon-arrow-down{
			transform: rotate(180deg);
		}
	}
	.drawer-slide-enter-active{
		margin-top: 0;
		transition: margin-top 0.2s;
	}
	.drawer-slide-enter {
		margin-top: -100%;
	}
	.drawer-slide-enter-to{
		margin-top: 0;
	}
	.drawer-slide-leave-active{
		margin-top: -100%;
		transition: margin-top 0.25s;
	}
	.drawer-slide-leave{
		margin-top: 0;
	}
	.drawer-slide-leave-to{
		margin-top: -100%;
	}
}
</style>
