import qs from "qs";
import {cloneDeep} from "lodash";
const isEmptyObject = (value) => (Object.prototype.toString.call(value) === "[object Object]" && Object.keys(value).length > 0);
const isArray = (value) => Object.prototype.toString.call(value) === "[object Array]";
const isObject = (value) => Object.prototype.toString.call(value) === "[object Object]";
/**
 * 高阶函数，封装某个api方法，返回一个新方法，此新方法可以将返回的数据存到localStorage
 * @param {function} api fn
 * @param {string} fnName
 * @param {string} option.type   sessionStorage:存在sessionStorage里  localStorage:存在localStorage里
 * @param {number} option.expire   过期日长 默认1小时(1*60*60*1000)
 * @return {function} new api fn
 */
export default function withStorageCache(fn,fnName="",option={}){
	option = Object.assign({},{
		type:"sessionStorage",
		expire:1*60*60*1000
	},option);
	fnName = fnName || fn.name;
	const {type,expire} = option;
	const KEY = `AJAX_API_STORAGE_${fnName}`;
    const outputFn = function(...args) {
        const key = args.map((arg) => {
            if (isArray(arg) || isObject(arg)) {
                return qs.stringify(arg);
            } else {
                return arg;
            }
        }).join("&");
        let allStorage = type==="sessionStorage" ? sessionStorage.getItem(KEY) : localStorage.getItem(KEY);
        if(allStorage){
			allStorage = JSON.parse(allStorage);
			const storageRes = allStorage[key];
			if(storageRes){
				const {storage,time} = storageRes;
				const now = new Date().getTime();
				if(time && (now-time)<expire){ //缓存还未过期
					return Promise.resolve(cloneDeep(storage));
				}
			}
        }
        return fn.apply(null, args).then((res) => {
            const {code} = res;
            if(code==200){
				const now = new Date().getTime();
                try{
					//把数据存进localStorage或sessionStorage;
					allStorage = allStorage || {};
					const storageRes = {storage:res,time:now};
					allStorage[key] = storageRes;
					const str = JSON.stringify(allStorage);
					if(type==="sessionStorage"){
						sessionStorage.setItem(KEY,str)
					}else{
						localStorage.setItem(KEY,str);
					}
                }catch(e){
                    console.error(e);
                }
            }
            return res
        })
	}
	outputFn.cache = {
		clear : function(){
			type==="sessionStorage" ? sessionStorage.removeItem(KEY) : localStorage.removeItem(KEY);
		}
	}
	return outputFn
}
