import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import NProgress from 'nprogress'; 
import 'nprogress/nprogress.css';
NProgress.configure({showSpinner:false});
Vue.use(VueRouter);
const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
    routes,
});
const vm = new Vue();
router.beforeEach((to,from,next) => {
	NProgress.start();
	//此处还需要做鉴权相关的逻辑判断..
    next();
    // let container = document.getElementById('appMain');
    // console.log(container.scrollTop)
    // if(container) container.scrollTop=0;
    
})

router.afterEach((to,from) => {
	NProgress.done();
	// NProgress.remove();
	const {name,meta} = to;
	const {cache} = meta || {};
	if(cache===false) return;
    name && store.commit("pushVisitedRouteName",{name,meta});

})

export default router;
