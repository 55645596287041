import {Modal} from "ant-design-vue";
export function confirm(config){
	return new Promise((resolve,reject) => {
		config = Object.assign({},{
			okText : "确定",
			cancelText : "取消",
		},config)
		config.onOk = () => resolve("confirm");
		config.onCancel = () => reject("cancel");
		Modal.confirm(config)
	})
}