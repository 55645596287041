import Antd from "ant-design-vue";
import * as helper from "./helper";
import * as filter from "./filter";
import WithDebounce from "@/component/with-debounce";
import dialogHeader from "@/component/dialog-header"; //dialog头部组件
import SvgIcon from "@/component/svg-icon";
import BreadCrumb from "@/component/bread-crumb";
import PageHeader from "@/component/page-header";
import CollapseTransition from "@/component/collapse-transition";
export default function bootstrap(Vue){
	Vue.use(Antd)
	Vue.helper = Vue.prototype.$helper = helper;
	Object.keys(filter).forEach((key) => Vue.filter(key,filter[key]));
	//把节流组件注册到全局(后期很多关键点击事件都需要做节流)
	Vue.component(WithDebounce.name,WithDebounce);
	Vue.component(SvgIcon.name,SvgIcon);
	Vue.component(BreadCrumb.name,BreadCrumb);
    Vue.component(PageHeader.name,PageHeader);
    Vue.component(dialogHeader.name,dialogHeader);
	Vue.component(CollapseTransition.name,CollapseTransition);
}
