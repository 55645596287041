import Vue from "vue";
import axios from "axios";
import qs from "qs";
import { uuid, getFormatTime, getCookie } from "@/utils/common";
// import {getCookie} from "@/utils/common"
const vm = new Vue();
const requst = axios.create({});
const API_MAP = {
    local: "http://pay-center.12301.local", //本地
    test: `http://pay-center.12301.test`, //内网
    release: "https://pay-center.12301dev.com:9443", //预生产
    gray: "//pay-center.gray.12301.cc", //灰度
    production: "https://pay-center.12301.cc", //正式
};
const LOGIN_URL_MAP = {
    local: "http://admin2014.12301.local", //本地
    test: "http://admin2014.12301.test", //内网
    release: "http://admin2014.12301dev.com", //预生产
    gray: "https://admin2014.gray.12301.cc", //灰度
    production: "https://admin2014.12301.cc", //正式
};
const nodeEnv = process.env.NODE_ENV || "development";
const deployEnv = process.env.VUE_APP_DEPLOY_ENV || "production";
// console.log('deployEnv',deployEnv)
const requstUrl = deployEnv !== "production" && nodeEnv === "development" ? "/api" : API_MAP[deployEnv];
// const requstUrl =  API_MAP[deployEnv]
// requst.defaults.withCredentials = true
requst.defaults.timeout = 20000;
requst.interceptors.request.use(
    (config) => {
        config = setHeader(config);
        config.data = qs.stringify(config.data);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
requst.interceptors.response.use(
    function (res) {
        const { data } = res;
        const { isToast = true } = res.config;
        // console.log(data)
        if (data.code !== 200) {
            onMessage(data && data.msg ? data.msg : "请求异常", isToast);
            return Promise.reject(res);
        }
        return res.data;
    },
    function (error) {
        // console.log(error.response)
        if (!error.response) {
            onMessage("请求超时");
            return Promise.reject(error);
        }
        const { data } = error.response;
        // if(error.response.status == 401) return  window.location.href = '/login'
        if (error.response.status == 401) {
            window.location.href = LOGIN_URL_MAP[deployEnv] + "/dlogin_n.html";
            return;
        }
        onMessage(data && data.msg ? data.msg : "请求异常");
        return Promise.reject(error);
    }
);

function onMessage(txt, isToast = true) {
    if (isToast) {
        vm.$alert(txt, "提示");
    }
}

function setHeader(config) {
    config.headers = config.headers || {};
    config.headers["Content-Type"] =
        "application/x-www-form-urlencoded;charset=UTF-8";
    config.headers["PFTTOKEN"] = getCookie("PHPSESSID") || "";
    //    config.headers['PHPSESSID'] ='1ad7ebceb8dd329bda5bfef7d07431e6'	;

    return config;
}

export function get(url, params) {
    return requst({
        url: requstUrl + url,
        method: "get",
        params,
    });
}

export function post(url, data) {
    let config = {
        url: requstUrl + url,
        method: "post",
        data,
    };
    return requst(config);
}

export function put(url, data) {
    let config = {
        url: requstUrl + url,
        method: "put",
        data: data,
    };
    return requst(config);
}
export function delet(url, data) {
    let config = {
        url: requstUrl + url,
        method: "delete",
        data: data,
    };
    return requst(config);
}

export default requst;
