import {post,get,delet,put} from '../axios/request';
import delay from "../helper/delay";
//获取交易记录列表
export function getOnlineTradeList(data){
    return post('/r/Manage_TradeQuery/getOnlineTradeList',data)
}
//获取所有业务方列表
export function getAllClient(data){
    return get('/r/Manage_Config/getAllClient',data)
}
//获取所有渠道
export function getAllChannel(data){
    return get('/r/Manage_Config/getAllChannel',data)
}
//获取支付方式和支付方法
export function getAllPayType(data){
    return get('/r/Manage_Config/getAllPayType',data)
}
//获取交易详情
export function getOnlineTradeDetail(data){
    return post('/r/Manage_TradeQuery/getOnlineTradeDetail',data)
}
//获取退款订单详情
export function getOnlineRefundetail(data){
    return post('/r/Manage_TradeQuery/getOnlineRefundetail',data)
}
//补发通知
export function notify(data){
    return post('/r/Manage_TradeQuery/notify',data)
}
//获取退款记录列表
export function getOnlineRefundList(data){
    return post('/r/Manage_TradeQuery/getOnlineRefundList',data)
}
//退款重试
export function addRefundRetry(data){
    return post('/r/Manage_TradeQuery/addRefundRetry',data)
}
//发起退款
export function refundOrder(data){
    return post('/r/Manage_TradeQuery/refund',data)
}
//补单
export function supplyOrder(data){
    return post('/r/Manage_TradeQuery/supplyOrder',data)
}
//归档时间列表
export function tradeArchiveDate(data){
    return post('/r/Manage_TradeQuery/tradeArchiveDate',data)
}
//导出
export function createExportTask(data){
    return post('/r/Manage_TradeExport/createExportTask',data)
}