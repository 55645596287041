import debounce from "@/helper/debounce";
export default{
	name : "WithDebounce",
	props : {
		immediate : { //在超过防抖时间间隔后，触发click事件时是否立即执行事件回调涵数
			type : Boolean,
			default : true
		},
		time : { //延迟时间(毫秒)
			type : [Number,String],
			default : 100
		},
	},
	created(){
		this.onClickHander = null;
	},
	render(){
		const vnode = this.$slots.default[0];
		if(!vnode) return null;
		const onClick = vnode.data.on && vnode.data.on.click;
		if(!onClick) return vnode;
		if(!this.onClickHander){
			this.onClickHander = debounce(onClick,this.time*1,true,this);
		}
		//劫持子组件(即slot)里的click，使事件回调函数具有防抖行为
		vnode.data.on.click = this.onClickHander;
		return vnode;
	}
}
