import dayjs from "dayjs";

//生成 uuid
export function uuid(length = 32) {
  const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  let str = ''
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length))
  }
  return str
}
/**
 * 按照指定的格式 取当前时间
 * @param {*} format  需转换的格式
 */
export function getFormatTime(format = 'YYYY-MM-DD HH:mm:ss'){
    return dayjs().format(format)
}

export function setCookie(c_name, value, expire) {
    var date = new Date()
    date.setSeconds(date.getSeconds() + expire)
    document.cookie = c_name + "=" + escape(value) + "; expires=" + date.toGMTString() + ";path=/"
    console.log(document.cookie)
  }

export  function getCookie(c_name) {
    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + "=")
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1
        var c_end = document.cookie.indexOf(";", c_start)
        if (c_end == -1) c_end = document.cookie.length
        return unescape(document.cookie.substring(c_start, c_end))
      }
    }
    return ""
}

  export function delCookie(c_name) {
    setCookie(c_name, "", -1)
  }