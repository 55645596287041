/**
 * 函数防抖
 * @param {function} fn  回调
 * @param {number} time  延迟时间(毫秒)
 * @param {*} immediate  是否立即执行
 * @param {*} ctx        回调函数执行时的上下文
 */
export default function debounce(fn,time=0,immediate=true,ctx){
	let __timer;
	ctx = ctx || this;
	return function(){
		const immediateCall = !__timer;
		const arg = arguments;
		if(__timer) clearTimeout(__timer);
		if(immediate){
			__timer = setTimeout(() => {
				if(!immediateCall) fn.apply(ctx,arg);
				__timer = null;
			},time)
			if(immediateCall) fn.apply(ctx,arg);
		}else{
			__timer = setTimeout(() => {
				fn.apply(ctx,arg)
			},time)
		}
	}
}
