/*
 * @Author: huangzhiyang 
 * @Date: 2021-05-28 10:04:11 
 * @Last Modified by: huangzhiyang
 * @Last Modified time: 2021-05-28 10:13:46
 * 应用app级数据管理
 */
const state = {
	userid : "",   //登录用户的唯一id
};
const mutations = {};
const actions = {};
const getters = {};

export default{
	namespaced : "app",
	state,
	mutations,
	actions,
	getters
}

