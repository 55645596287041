<template>
    <div class="app-main" id="appMain">
        <!-- {{$route.name}} -->
        <transition name="fade-transform" mode="out-in">
            <keep-alive :include="visitedRouteName">
                <router-view :key="key" />
            </keep-alive>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "AppMain",
        computed: {
            visitedRouteName() {
                return this.$store.state.visitedRouteName;
            },
            key() {
                return this.$route.fullPath
            }
        },
        mounted(){}
        
    }
</script>

<style lang="scss" scoped>
    .app-main {
        min-width: 1214px;
        padding: 16px;
        overflow: auto;
        overflow-x: hidden;
    }
</style>