<template>
<div class="globalPageHeader comp" :flex="flex">
	<div v-if="breadCrumb" flex="cross:center">
		<bread-crumb/>
        <slot></slot>
		<a @click="goBack" class="backButton">
			<svg-icon name="icon-return1" style="font-size:16px;fill:#818899"></svg-icon>
			<span>返回</span>
		</a>
	</div>
	<!-- <div v-if="sync" flex="cross:center" class="syncBar">
		<svg-icon name="icon-pft_icon_check-circle" style="font-size:16px;fill:#818899;margin-right:3px"></svg-icon>
		<span>最近同步时间：{{syncStatus.lastSyncAt}}</span>
		<a class="syncBtn" @click="systemSync" >一键同步</a>
	</div> -->
</div>
</template>
<script>
// import * as userInitStore from "@/store/userInit.js"
// import { sync } from "@/api/commonApi"
import { Message } from "element-ui";
// import { syncStatus } from '@/api/homeApi'
export default {
	name : "PageHeader",
	props : {
		breadCrumb : { //是否显示面包屑
			type : Boolean,
			default : true
		},
		backButton : { //是否显示返回按钮
			type : Boolean,
			default : false
		},
		sync : { //是否显示同步功能
			type : Boolean,
			default : false
		}
    },
    data(){
        return{
            timer:null
        }
    },
	computed : {
		flex(){
			const {breadCrumb,sync} = this;
			if(breadCrumb && sync) return "cross:center box:last";
			if(breadCrumb) return "cross:center main:left";
			if(sync) return "cross:center main:right";
        },
        // syncStatus: () => userInitStore.state.syncStatus, //公司信息
	},
	methods : {
		goBack(){
			this.$router.back();
        },
        // async systemSync(){
        //     const res = await sync().catch(e => e);
        //     if(res.code != 0) return;
        //     this.setTimer(500);
        // },
        // //轮询同步状态
        // setTimer(times){
        //     this.timer = setInterval(async ()=>{
        //         const res = await syncStatus().catch(e => {clearInterval(this.timer)})
        //         if(res.status != 1 && res.status !=2){
        //             clearInterval(this.timer)
        //             if(res.status == 4) {
        //                 Message.success('同步成功');
        //                 location.reload();
        //             }
        //         }
        //     },times)
        // }
	}
}
</script>
<style lang="scss" scoped>
.globalPageHeader.comp{
    // min-width:1182px;
	padding: 18px 16px;
	background: #fff;
	margin-bottom: 16px;
	.backButton{
		width: 56px;
		height: 24px;
		line-height: 20px;
		text-align: center;
		border: 1px solid $bc;
		color: $normal-2;
		margin-left: 45px;
		font-size: 12px;
		cursor: pointer;
	}
	.syncBar{
		color: $normal-2;
		.syncBtn{
			padding: 5px 16px;
			color: $brand;
			border: 1px solid $brand;
			border-radius: 2px;
			margin-left: 24px;
			cursor: pointer;
		}
	}
}
</style>