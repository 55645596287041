<template>
    <div class="app-sidebar" :class="{ 'app-sidebar-collapse': isCollapse }">
        <el-menu :default-active="$route.name" :collapse="isCollapse">
            <template v-for="route in sidebarRoutes">
                <el-submenu
                    v-if="route.children && route.children.length"
                    :index="route.path"
                    :key="route.path"
                >
                    <template slot="title">
                        <i :class="`el-icon-${route.meta.elIcon}`"></i>
                        <span slot="title">{{ route.meta.title }}</span>
                    </template>
                    <el-menu-item
                        v-for="subRoute in route.children"
                        :index="subRoute.name"
                        :key="subRoute.name"
                        @click="onSubItemClick(route, subRoute)"
                    >
                        {{ subRoute.meta.title }}
                    </el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
        <div class="collapseBtn" @click="handleChangeCollapse">
            <i v-if="!isCollapse" class="el-icon-arrow-left"></i>
            <i v-else class="el-icon-arrow-right"></i>
        </div>
    </div>
</template>

<script>
import { getSidebarRoute } from "@/router/routes";
export default {
    name: "AppSidebar",
    data() {
        return {
            sidebarRoutes: getSidebarRoute(),
            isCollapse: false,
        };
    },
    mounted() {
        console.log("sidebarRoutes", this.sidebarRoutes);
    },
    methods: {
        onSubItemClick(route, subRoute) {
            const { name } = subRoute;
            if (!name) return;
            if (this.$route.name == name) return;
            this.$router.push({ name });
        },
        handleChangeCollapse() {
            this.isCollapse = !this.isCollapse;
        },
    },
};
</script>

<style lang="scss">
.el-submenu__title {
    font-weight: 700;

    .el-submenu__icon-arrow {
        font-size: 14px;
        font-weight: bolder;
        color: #000;
    }
}
</style>
<style lang="scss" scoped>
.app-sidebar {
    position: relative;
    width: $app-sidebar-width;
    background: #fff;
    // padding-top: 16px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    // overflow-y: auto;

    &-collapse {
        width: 64px;
    }
    .el-menu {
        border-right: 0;
        height: 100%;
        overflow-y: auto;

        .el-menu-item:not(.is-active) {
            color: #555;
        }
        .el-menu-item.is-active {
            background: #ecf5ff;
            border-right: 2px solid #4d7efa;
        }
    }

    .collapseBtn {
        position: absolute;
        width: 10px;
        height: 50px;
        right: -10px;
        top: 50%;
        margin-top: -25px;
        background: #4fabff;
        color: #fff;
        border-radius: 0 8px 8px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
</style>
