export function getScrollTop(element){
	return "scrollTop" in element ? element.scrollTop : element.pageYOffset;
}
export function setScrollTop(element,value){
	if("scrollTop" in element){
		element.scrollTop = value;
	}else{
		element.scrollTo(element.scrollX,value);
	}
}

export function getElementTop(element){
	if(element===window) return 0;
	return element.getBoundingClientRect().top + getScrollTop(window);
}

/**
 * 获取一个元素的可视高度
 * @param {HTMLELEMENT} element 
 */
export function getVisibleHeight(element){
	if(element===window) return window.innerHeight;
	return element.getBoundingClientRect().height;
}

export function getScrollHeight(element){
	return element===window ? document.documentElement.scrollHeight : element.scrollHeight;
}

export function scrollable(element,{offset=0}={}){
	const __callback__ = {
		scroll : [],
		reachBottom : []
	};
	let disable = false;
	const scrollFn = (e) => {
		if(disable) return false;
		const scrollerHeight = getVisibleHeight(element);
		if(!scrollerHeight || (element!==window && window.getComputedStyle(element).display==="none")) return false;
		const scrollTop = getScrollTop(element);
		const visibleHeight = getVisibleHeight(element);
		const totalHeight = scrollTop + visibleHeight;
		const scrollHeight = getScrollHeight(element);
		const atBottom = scrollHeight - Math.ceil(totalHeight) <= offset;
		e.scrollTop = scrollTop;
		if(atBottom){
			__callback__.reachBottom.forEach((fn) => {
				fn(e);
			})
		}else{
			__callback__.scroll.forEach((fn) => {
				fn(e);
			})
		}
	}
	element.addEventListener("scroll",scrollFn);
	return {
		destroy(){
			element.removeEventListener("scroll",scrollFn);
		},
		disable(){
			disable = true;
		},
		enable(){
			disable = false;
		},
		on(type,fn){
			if(typeof type==="string" && typeof fn==="function" && __callback__[type]){
				__callback__[type].push(fn)
			}
		},
		off(type,fn){
			if(!__callback__[type]) return false;
			if(typeof fn!=="function") return __callback__[type] = [];
			__callback__[type] = __callback__[type].filter((item) => item!==fn);
		}
	}
}

/**
 * 检测一个元素的滚动状态(什么时候开始滚动，什么时候停止滚动)
 * @param {*} element 
 */
export function checkScrollStatus(element,timeDis=500){
	if(!element) element = window;
	let t1 = 0;
	let t2 = 0;
	let timer = null;
	const __callback__ = {
		scrolling : [],
		stop : []
	};
	let disable = false;
	const scrollFn = (e) => {
		if(disable) return false;
		__callback__.scrolling.forEach((fn) => {
			fn(e);
		})
		t1 = getScrollTop(element);
		clearTimeout(timer);
		timer = setTimeout(function(e){
			isScrollStop(e);
		},timeDis)
	}
	const isScrollStop = (e) => {
		t2 = getScrollTop(element);
		if(t2==t1){
			__callback__.stop.forEach((fn) => {
				fn(e);
			})
		}
	}
	element.addEventListener("scroll",scrollFn);
	return {
		destroy(){
			if(timer) clearTimeout(timer);
			element.removeEventListener("scroll",scrollFn);
		},
		disable(){
			disable = true;
		},
		enable(){
			disable = false;
		},
		on(type,fn){
			if(typeof type==="string" && typeof fn==="function" && __callback__[type]){
				__callback__[type].push(fn)
			}
		},
		off(type,fn){
			if(!__callback__[type]) return false;
			if(typeof fn!=="function") return __callback__[type] = [];
			__callback__[type] = __callback__[type].filter((item) => item!==fn);
		}
	}
}
