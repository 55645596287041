import Ant from "ant-design-vue/dist/antd.css";
import "@/assets/styles/style.scss";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bootstrap from "./bootstrap";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import { initGlobalData } from "./store/init";
Vue.config.productionTip = false;
Vue.use(Ant)
Vue.use(ElementUI)
Vue.directive("focus", {
    inserted(el){
        if(el.tagName!="INPUT"){
            el.querySelector("input").focus()
        }else{
            el.focus();
        }
    }
})
bootstrap(Vue);
function init(){
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
}
init()
initGlobalData();



