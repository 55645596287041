import Vue from 'vue';
import {getAllClient,getAllChannel,getAllPayType} from "@/api/order"
const INIT_STATE = () => ({
    //支付方式
    payTypeList:[
        {
            id:1,
            payType_name:'支付宝'
        },{
            id:2,
            payType_name:'微信支付'
        },{
            id:3,
            payType_name:'云闪付'
        },{
            id:4,
            payType_name:'数字人民币'
        }
    ],
    payStatusList:[
        {
            id:-1,
            label:'全部'
        },{
            id:0,
            label:'未支付'
        },{
            id:1,
            label:'已支付'
        },{
            id:2,
            label:'已退款'
        },{
            id:3,
            label:'部分退款'
        }
    ],
    clientList:[],
    channelList:[],

})
export const state = Vue.observable(INIT_STATE());
export async function updateClientList(){
    const resClient = await getAllClient().catch(e => e); 
    if(resClient.code == 200){
        state.clientList = resClient.data || [];
    }
}
export async function updateChannelList(){
    const resChannel = await getAllChannel().catch(e => e);
    if(resChannel.code == 200){
        state.channelList = resChannel.data || [];
    }
}
export async function  initGlobalData(){
    updateClientList();
    updateChannelList();
}