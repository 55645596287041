<template>
    <div class="dialogHeader comp" flex="main:justify cross:center">
        <div class="text" flex="cross:center">{{title}}</div>
        <img class="close_icon" src="../../assets/image/pft_icon_close.png" @click="closeDialog">
    </div>
</template>

<script>
    export default {
        name: "dialogHeader",
        props: {
            title: {
                type: String,
                required: true,
                default: "",
            },
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialog')
    
            }
        }
    
    };
</script>

<style lang="scss">
    .dialogHeader.comp {
        height: 56px;
        border-bottom: 1px solid #ebeef5;
        .text {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #40444D;
            padding-left: 27px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 3px;
                height: 16px;
                background: #4D7EFA;
                background: #39a9ef;
                top: 3px;
                left: 16px;
            }
        }
        .close_icon {
            cursor: pointer;
            padding: 0 16px;
            width: 48px;
            height: 16;
        }
    }
</style>