<template>
<span>
	<svg class="svg-icon" :class="iconClass" aria-hidden="true">
		<use :xlink:href="'#' + name"></use>icon-pft_icon_close
	</svg>
</span>
</template>

<script>

export default {
	name : "SvgIcon",
	props: {
		name: {
			type: String,
			required: true,
			default: "",
		},
	},
	computed: {
		iconClass() {
			return ["icon-" + this.name,this.name];
		}, 
	}
};
</script>
<style lang="scss">
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	// fill: currentColor;
	overflow: hidden;
}
</style>
