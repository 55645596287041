<template>
    <div class="app-header" flex="cross:center box:last">
        <a class="logo" flex="cross:center">
            <!-- <img src="~@/assets/image/logo.png" alt=""> -->
            <img class="logoWrap" src="//static.12301.cc/assets/build/images/pft-header-logo.png" alt="">
        </a>
        <div flex="cross:center">
            <!-- <div style="margin:0 15px 0 0;cursor:pointer;" @click="setting">
                <i class="el-icon-setting"></i> 设置
            </div>
            <div style="margin:0 15px 0 0;cursor:pointer;" @click="personal">
                <i class="el-icon-user"></i> 个人中心
            </div>
            <img class="photoBox" :src="userInfo.headphoto" alt="">
            <span class="username ellipsis">{{userInfo.dname || '11111111'}}</span>
            <with-debounce time="1000">
                <a class="logoutBtn" flex="cross:center" @click="loginOut">
                    <svg-icon name="icon-sign-out1" style="margin-left:3px;fill:#fff" />
                    <i>退出</i>
                </a>
            </with-debounce> -->
        </div>
    </div>
</template>

<script>
    // import * as initStore from "@/store/init.js"
    import {
        getSidebarRoute
    } from "@/router/routes";
    export default {
        name: "AppHeader",
        // computed: {
        //     userInfo: () => initStore.state.userInfo,
        //     menu: () => initStore.state.menu,
        // },
        data() {
            return {}
        },
        mounted() {},
        // methods: {
        //     async loginOut() {
        //         sessionStorage.removeItem('pftToken');
        //         window.location.href = '/login'
        //     },
        //     setting() {
        //         this.$router.push({
        //             name: 'SetRole'
        //         });
        //     },
        //     personal() {

        //         this.$router.push({
        //             name: 'PersonAccountInfo'
        //         });
        //     },
        //     tabsEvent(val) {
        //         let route = val.children[0] && val.children[0].children[0].path || ''
        //         this.$router.push({
        //             name: route
        //         });
        //     }
    
        // }
    }
</script>

<style scoped lang="scss" scoped>
    .app-header {
        height: $app-header-height;
        min-width: 666px;
        color: #fff;
        background: linear-gradient(90deg, #4c91f6, #4fabff);
        padding-right: 16px;
        overflow-x:auto;
        .logo {
            height: $app-header-height;
            // &>img {
            //     height: $app-header-height;
            // }
            .logoWrap {
                height: 40px;
                width:auto;
                margin-left: 14px;
                background-repeat: no-repeat;
            }
        }
        .photoBox {
            display: inline-block;
            width: 32px;
            height: 32px;
            font-size: 0;
            overflow: hidden;
            background: #999;
            border-radius: 50%;
            margin-right: 5px;
        }
        .username {
            position: relative;
            padding-right: 8px;
            margin-right: 8px;
            max-width: 120px;
            // &:after {
            //     position: absolute;
            //     top: 5px;
            //     bottom: 5px;
            //     right: 0;
            //     content: "";
            //     border-left: 1px solid #fff;
            // }
        }
        .versionName {
            cursor: pointer;
        }
        .logoutBtn {
            color: #fff;
            margin-left: 15px;
            cursor: pointer;
            .svg-icon {
                margin-left: 2px;
            }
        }
    }
</style>