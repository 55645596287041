export default function getUrlParams(){
	const loc = window.location;
	const reg = /(([^?&=]+)(?:=([^?&=]*))*)/g;
	const search = loc.search || "";
	const hash = loc.hash || "";
	const newQuery = {};
	const newHash = {};
	if(search){
		search.replace(reg,function(){
			const key = arguments[2];
			let val = arguments[3] || "";
			val = val.replace(/\//,"");
			newQuery[key] = val;
		})
	}
	if(hash){
		const hashStr = hash.split("?")[1] || "";
		hashStr.replace(reg,function(){
			const key = arguments[2];
			const val = arguments[3] || "";
			newHash[key] = val;
		})
	}
	return Object.assign({},newQuery,newHash);
}