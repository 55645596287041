const files = require.context(".",false,/\.js$/);
const helper = files.keys().filter((item) => item!=="./index.js").reduce((helper,path) => {
    const fnName = path.replace(/^\.\//,"").replace(/\.js$/,"");
    const module = files(path);
    const keys = Object.keys(module);
    if(module.default){
        helper[fnName] = module.default
    }else{
        helper[fnName] = {};
        keys.forEach((key) => {
            if(key!=="default" && typeof module[key]==="function"){
                helper[fnName][key] = module[key];
            }
        })
    }
    return helper;
},{})

export default helper;
